
import { Component, Vue } from 'vue-property-decorator'
import { CarIllegalInfo } from '@/types/carManage'

@Component({
  name: 'CarManageIllegallList'
})
export default class extends Vue {
  private info = {
    projectName: '',
    machineName: '',
    typeId: '',
    startTime: '',
    endTime: ''
  }

  private dateArr: string[] = []

  private page = 1
  private size = 10
  private total = 0

  private loading = false
  private tableData: CarIllegalInfo[] = []

  get carTypeList () {
    return this.$store.state.carTypeList
  }

  created () {
    this.getList()
  }

  onSearch () {
    this.page = 1
    this.getList()
    console.log(this.info)
  }

  getList () {
    this.loading = true
    this.$axios.get<{total: number; list: CarIllegalInfo[]}>(this.$apis.carManage.selectVehicleViolationByPage, {
      ...this.info,
      page: this.page,
      size: this.size
    }).then(res => {
      if (res) {
        this.total = res.total || 0
        this.tableData = res.list || []
      }
    }).finally(() => {
      this.loading = false
    })
  }

  dateChange (date: string[]) {
    if (date) {
      this.info.startTime = date[0]
      this.info.endTime = date[1]
    } else {
      this.info.startTime = ''
      this.info.endTime = ''
    }
  }

  onDelete (violationId: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.loading = true
        this.$axios.post(this.$apis.carManage.deleteDataVehicleViolation, { violationId }).then(() => {
          this.$message.success('删除成功')
          this.getList()
        }).finally(() => {
          this.loading = false
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  // 新增车辆
  onAdd () {
    this.$router.push({ name: 'addIllegal' })
  }

  onEdit (id: string) {
    this.$router.push({
      name: 'editIllegal',
      params: { id }
    })
  }
}
